



























import {Component, Vue} from "vue-property-decorator";
import {isWx as checkWx} from "@/utils/system";

@Component({
  name: "NavBar",
  components: {},
  beforeRouteEnter(to: any, from, next) {
    next((vm: any) => {
      vm.title = to.meta.title
    })
  },
  beforeRouteUpdate(to: any, from, next) {
    (this as any).title = to.meta.title
    next()
  }
})

export default class NavBar extends Vue {
  public title: string = ''
  public showNav: boolean = true

  created() {
    this.showNav = !this.isWx()
  }

  routerPageUp() {
    if ((this as any).$router.options.routes.length) {
      this.$router.go(-1)
    }
  }

  routerGoHome() {
    if (this.$route.name !== 'home') {
      this.$router.push('/home')
    }
  }

  isWx() {
    return checkWx()
  }

}
